<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container grid-list-xl fluid v-else>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Lista de cálculos anuales'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                    >
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Clientes"
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="cliente_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Registros patronales"
                                    :items="registrosPatronales"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="registro_patronal_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Empresas"
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empresa_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" cols="12" xs="12" sm="6" md="6" class="pb-0 ma-0" >
                                <v-autocomplete
                                    outlined
                                    label="Año"
                                    :items="items_anios"
                                    item-text="nombreAnio"
                                    item-value="id"
                                    persistent-hint
                                    v-model="anio_fiscal_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.registro_patronal}} - {{item.claveRP}}</div></td>
                                <td><div>{{item.empresa}}</div></td>
                                <td><div>{{item.anio}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark @click="detalle(item)" small>
                                                    <v-icon class="iconoDelgadoBoton" v-text="'$visibility_outline'"></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Ver lista de cálculo anual de empleados</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
        
    </div>
</template>

<script>
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';

//APIS
import apiClientes from "@/api/clientes";
import apiEmpresas from "@/api/empresas";
import apiAnios from '@/api/anual_fiscal';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiAjusteAnual from "@/api/nominas/ajusteAnual";

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            url         : "ajuste-anual/find",
            columns     : [
                {
                    label: 'Registro Patronal',
                    name: 'registro_patronal',
                    filterable: false
                },
                {
                    label: 'Empresa',
                    name: 'empresa',
                    filterable: false
                },
                {
                    label: 'Año',
                    name: 'anio',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
                
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc",
            },
            
            datosLogin              : null,
            rol                     : null,

            cliente_id              : null,
            registro_patronal_id    : null,
            empresa_id              : null,
            anio_fiscal_id          : null,

            clientes                : [],
            registrosPatronales     : [],
            empresas                : [],
            items_anios             : [],
            
        }
    },
    watch:{
        cliente_id: function(val){
            if(val != null){
                this.cambiaCliente(val);
            }
        },
        registro_patronal_id:function(val){
            if(val != null){
                this.cambiaRegistroPatronal();
            }
        },
        empresa_id:function(val){
            if(val != null){
                this.getAnios();
            }
        },
    },
    methods: {
        /**
         * @method getClientes Este método realiza la petición a la API de clientes.
         * @description Este método se ejecuta al seleccionar al iniciar la vista. El resultado se almacena en el array clientes=[]
        */
        async getClientes() {
            let param = { 
                activo: true, 
                paginate: false 
            };
            await apiClientes.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        async cambiaCliente(cliente){
            if(cliente == null){
                return;
            }

            let param = { 
                activo: true, 
                paginate: false, 
                cliente_id: cliente 
            };
            await apiRegistroPatronal.find(param).then(response => {
                //this.registrosPatronales = response.data;
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
        },

        /**
         * @method cambiaRegistroPatronal Este método realiza la petición a la API de Empresas.
         * @description Este método se almacena en el array empresas=[]. Se ejecuta al seleccionar al menos una empresa.
        */
        async cambiaRegistroPatronal(){
            let registrosPatronalesSeleccionados = []
            if(this.registro_patronal_id != null){
                registrosPatronalesSeleccionados.push(this.registro_patronal_id)
            }
            let param = {   
                activo: true, 
                paginate: false, 
                cliente_id: this.cliente_id,
                arrayRegistroPatronal: registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(registrosPatronalesSeleccionados)
            };

            if(registrosPatronalesSeleccionados.length == 0){
                this.empresas = []
                return
            }

            await apiEmpresas.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method getAnios Este método realiza la petición a la API de años.
         * @description Este método se ejecuta al seleccionar al menos una empresa. El resultado se almacena en el array items_anios=[]
        */
        async getAnios() {
            if(this.empresa_id == null){
                this.items_anios = []
                return
            }
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_id,
                empresa_id  : this.empresa_id
            }

            await apiAnios.find(param).then((response) => {
                this.items_anios = response.data;
                this.items_anios.map(emp => emp.nombreAnio = emp.anio.nombre);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los años.");
            })
        },

        async loadModalData() {
            this.cliente_id              = null;
            this.registro_patronal_id    = null;
            this.empresa_id              = null;
            this.anio_fiscal_id          = null;
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
            } else if (this.rol == "admin-empresa") {
            
            } else if (this.rol == "admin-sucursal") {
                
            } else if (this.rol == "admin-depto") {
            
            }
        },
        setFilters() {
            let cliente = this.cliente_id;
            let empresa = this.empresa_id;
            let rp = this.registro_patronal_id;
            let anio = this.anio_fiscal_id;
            
            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } 
            else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } 
            

            let filterParams = {
                cliente_id: cliente,
                empresa_id: empresa,
                registro_patronal_id : rp,
                anio_fiscal_id : anio
                
            };

            let defaultParams = {
                activo: true,
                paginate: true,
            };

            
            if (filterParams != null) Object.assign(defaultParams, filterParams);

            this.filters = defaultParams;

            this.cliente_id              = null;
            this.registro_patronal_id    = null;
            this.empresa_id              = null;
            this.anio_fiscal_id          = null;
        },
        
        /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },

        detalle(data){
            this.$session.remove("calculoAnual");
            this.$session.set('calculoAnual',data);
            this.$router.push('/detalleCalculoAnual');
        },
        eliminar(data){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el registro de cálculo anual?",
                "Eliminar",
                () => {
                    apiAjusteAnual.eliminarEmpleadoDetalle(data.id).then(response => {
                        Notify.Success("Registro eliminado", "El registro ha sido eliminado satisfactoriamente");
                        this.$refs.tabla.getData();
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
    },
    async created(){
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        if (this.rol == "root") {
            this.getClientes();
        }
        else if (this.rol == "admin") {
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.cliente_id = this.datosLogin.cliente_id;
            await this.cambiaCliente(this.cliente_id);
        }
        else if (this.rol == "admin-empresa") {
            this.filters.cliente_id = this.datosLogin.cliente_id;
            this.cliente_id = this.datosLogin.cliente_id;
            this.empresa_id = this.datosLogin.empresa_id;
            await this.cambiaCliente(this.datosLogin.cliente_id);
        }
        else if(this.rol == "admin-sucursal"){
            Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
        }
        else{
            this.filters.cliente_id = this.datosLogin.cliente_id;
        }
    }
}
</script>

<style scoped>

</style>
