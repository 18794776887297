import Vue from 'vue';

export default {
    getEmpleados(parametros) {
        return Vue.axios.post("empleados/ajuste-anual", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    ajusteAnual(parametros) {
        return Vue.axios.post("calculo-nomina/ajuste-anual", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    findDetalle(parametros) {
        return Vue.axios.get("ajuste-anual/detalle", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    eliminarEmpleadoDetalle(id) {
        return Vue.axios.delete("ajuste-anual/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    eliminarEmpleadoDetalleMasivo(parametros) {
        return Vue.axios.post("ajuste-anual/delete", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getCatalogos(id) {
        return Vue.axios.get("ajuste-anual/catalogos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addDocumento(parametros){
        return Vue.axios.post("ajuste-anual/sube_documento", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}